import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import './MyStories.scss';

const MyStories = ({ stories, userId, login, logoImage, onClose, onNext, onPrevious, onDeleteStory }) => {
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
  const [progress, setProgress] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [storyToDelete, setStoryToDelete] = useState(null);
  const [isPaused, setIsPaused] = useState(false); // Для паузы при зажатии
  const [contentLoaded, setContentLoaded] = useState(false); // Для контроля загрузки медиа
  const [storyDuration, setStoryDuration] = useState(null); // Длительность текущего сториса в мс
  const [viewers, setViewers] = useState([]); // Список пользователей, просмотревших сторис
  const [viewersModalOpen, setViewersModalOpen] = useState(false); // Флаг для открытия модального окна просмотров
  const navigate = useNavigate();
  const intervalRef = useRef(null); // Ссылка на интервал

  const currentStory = stories[currentStoryIndex];

  // При смене сториса сбрасываем загрузку контента, длительность и прогресс
  useEffect(() => {
    setContentLoaded(false);
    setStoryDuration(null);
    const totalStories = stories.length;
    setProgress(new Array(totalStories).fill(0));
  }, [currentStoryIndex, stories]);

  // При смене текущего сториса – запрашиваем список просмотров
  useEffect(() => {
    if (!currentStory) return;
    const fetchViewers = async () => {
      try {
        const token = localStorage.getItem('jwtToken');
        const res = await axios.get(`/api/story/${currentStory.id}/views`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        // Ожидается, что сервер возвращает { viewers: [...] }
        setViewers(res.data.viewers);
      } catch (error) {
        console.error("Ошибка при получении просмотров сторис:", error);
      }
    };
    fetchViewers();
  }, [currentStory]);

  useEffect(() => {
    // Запускаем интервал только если контент загружен, не на паузе и длительность известна
    if (!currentStory || isPaused || !contentLoaded || !storyDuration) return;

    const updateInterval = 100; 
    const progressStep = 100 / (storyDuration / updateInterval);

    intervalRef.current = setInterval(() => {
      setProgress((prev) => {
        const updatedProgress = [...prev];
        updatedProgress[currentStoryIndex] += progressStep;
        if (updatedProgress[currentStoryIndex] >= 100) {
          clearInterval(intervalRef.current);
          handleNextStory();
        }
        return updatedProgress;
      });
    }, updateInterval);

    return () => clearInterval(intervalRef.current);
  }, [currentStoryIndex, stories, isPaused, contentLoaded, storyDuration]);

  const handleNextStory = () => {
    if (currentStoryIndex < stories.length - 1) {
      setCurrentStoryIndex((prevIndex) => prevIndex + 1);
    } else {
      onClose();
    }
  };

  const handlePreviousStory = () => {
    if (currentStoryIndex > 0) {
      setCurrentStoryIndex((prevIndex) => prevIndex - 1);
    } else {
      onPrevious();
    }
  };

  const handlePause = () => {
    clearInterval(intervalRef.current);
    setIsPaused(true);
  };

  const handleResume = () => {
    setIsPaused(false);
  };

  const handleContentClick = (e) => {
    const { clientX, currentTarget } = e;
    const { offsetWidth } = currentTarget;
    if (clientX < offsetWidth / 2) {
      handlePreviousStory();
    } else {
      handleNextStory();
    }
  };

  const handleDeleteConfirmation = () => {
    setShowDeleteConfirmation(true);
    setStoryToDelete(currentStory.id);
  };

  const handleDeleteStory = () => {
    if (storyToDelete) {
      onDeleteStory(storyToDelete);
      setShowDeleteConfirmation(false);
      handleNextStory();
    }
  };

  const getTimeAgo = (timestamp) => {
    const now = new Date();
    const storyDate = new Date(timestamp);
    const diffInSeconds = Math.floor((now - storyDate) / 1000);
    if (diffInSeconds < 60) {
      return `${diffInSeconds} секунд назад`;
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes} минут назад`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} часов назад`;
    } else {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} дней назад`;
    }
  };

  // Функция открытия модального окна с полным списком просмотров
  const openViewersModal = () => {
    setViewersModalOpen(true);
  };

  const closeViewersModal = () => {
    setViewersModalOpen(false);
  };

  if (!currentStory) {
    return null;
  }

  return (
    <div className="myStories-viewer">
      <div className="myStories-header">
        <div className="myStories-user-info">
          <img src={logoImage} alt={login} className="myStories-avatar" />
          <div className="myStories-user-details">
            <span className="myStories-login">{login}</span>
            <span className="myStories-time-ago">{getTimeAgo(currentStory.createdAt)}</span>
          </div>
        </div>
        <div className="myStories-actions">
          <button
            className="myStories-delete-btn"
            onClick={handleDeleteConfirmation}
          >
            •••
          </button>
          <button className="myStories-close-btn" onClick={onClose}>✕</button>
        </div>
      </div>

      <div className="myStories-progress-bar">
        {stories.map((_, index) => (
          <div
            key={index}
            className={`myStories-progress-segment ${index === currentStoryIndex ? 'active' : ''}`}
            style={{
              width: `${100 / stories.length}%`,
              background: progress[index] >= 100 ? 'white' : 'rgba(255, 255, 255, 0.3)',
            }}
          >
            <div
              className="myStories-progress-fill"
              style={{
                width: `${Math.min(progress[index], 100)}%`,
                background: 'white',
              }}
            ></div>
          </div>
        ))}
      </div>

      <div
        className="myStories-content"
        onClick={handleContentClick}
        onMouseDown={handlePause}
        onMouseUp={handleResume}
        onTouchStart={handlePause}
        onTouchEnd={handleResume}
      >
        {currentStory.fileType.startsWith('image') ? (
          <img
            src={currentStory.fileUrl}
            alt="Story"
            className="myStories-media"
            onLoad={() => setContentLoaded(true)}
          />
        ) : currentStory.fileType.startsWith('video') ? (
          <video
            src={currentStory.fileUrl}
            className="myStories-media"
            autoPlay={!isPaused}
            playsInline
            weblit-playsInline='true'
            onLoadedMetadata={(e) => {
              setContentLoaded(true);
              setStoryDuration(e.target.duration * 1000);
            }}
            onEnded={handleNextStory}
          ></video>
        ) : (
          <div>Unsupported file type</div>
        )}
      </div>

      <div className="myStories-text">
        <p>{currentStory.text}</p>
      </div>

      {/* Блок для предпросмотра просмотров сторис (расположен в левом нижнем углу) */}
      {viewers && viewers.length > 0 && (
        <div className="myStories-viewers-preview" onClick={openViewersModal}>
          {viewers.slice(0, 2).map((user) => (
            <img key={user.id} src={user.logoImage} alt={user.login} />
          ))}
          {viewers.length > 2 && <span>+{viewers.length - 2}</span>}
        </div>
      )}

      {showDeleteConfirmation && (
        <div className="myStories-delete-confirmation">
          <p>Удалить эту сторис?</p>
          <button className="myStories-confirm-btn" onClick={handleDeleteStory}>
            Удалить
          </button>
          <button
            className="myStories-cancel-btn"
            onClick={() => setShowDeleteConfirmation(false)}
          >
            Отмена
          </button>
        </div>
      )}

      {/* Модальное окно с полным списком просмотров */}
      {viewersModalOpen && (
        <div className="myStories-viewers-modal">
          <div className="myStories-viewers-modal-content">
            <h2>Просмотров {viewers.length}</h2>
            <ul>
              {viewers.map((user) => (
                <li key={user.id}>
                  <Link to={`/userProfile/${user.id}`} className='myStories-viewers-modalContent-user'>
                    <img src={user.logoImage} alt={user.login} />
                    <span>{user.login}</span>
                  </Link>
                </li>
              ))}
            </ul>
            <button onClick={closeViewersModal}>Закрыть</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyStories;
