import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './StoryViewer.scss';

const StoryViewer = ({ stories, userId, login, logoImage, onClose, onNext, onPrevious, nextUserStories }) => {
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
  const [progress, setProgress] = useState([]);
  const [isPaused, setIsPaused] = useState(false); // Флаг для паузы
  const intervalRef = useRef(null);
  const navigate = useNavigate();

  const currentStory = stories[currentStoryIndex];

  useEffect(() => {
    if (!currentStory || isPaused) return; // Не обновляем прогресс, если на паузе

    const totalStories = stories.length;
    const initialProgress = new Array(totalStories).fill(0);
    setProgress(initialProgress);

    const storyDuration = currentStory.fileType.startsWith('image') ? 5000 : null; // Для видео будет управляться встроенно
    const updateInterval = 100;
    const progressStep = (100 / (storyDuration / updateInterval));

    if (storyDuration) {
      intervalRef.current = setInterval(() => {
        setProgress((prev) => {
          const updatedProgress = [...prev];
          updatedProgress[currentStoryIndex] += progressStep;

          if (updatedProgress[currentStoryIndex] >= 100) {
            clearInterval(intervalRef.current);
            handleNextStory();
          }

          return updatedProgress;
        });
      }, updateInterval);
    }

    return () => clearInterval(intervalRef.current);
  }, [currentStoryIndex, stories, isPaused]);

  const handleNextStory = () => {
    if (currentStoryIndex < stories.length - 1) {
      setCurrentStoryIndex((prevIndex) => prevIndex + 1);
    } else {
      if (nextUserStories) {
        onNext(nextUserStories);
      } else {
        onNext();
      }
    }
  };

  const handlePreviousStory = () => {
    if (currentStoryIndex > 0) {
      setCurrentStoryIndex((prevIndex) => prevIndex - 1);
    } else {
      onPrevious();
    }
  };

  const handleContentClick = (e) => {
    const { clientX, currentTarget } = e;
    const { offsetWidth } = currentTarget;

    if (clientX < offsetWidth / 2) {
      handlePreviousStory();
    } else {
      handleNextStory();
    }
  };

  const handleVideoEnded = () => {
    handleNextStory();
  };

  const handleProfileRedirect = () => {
    navigate(`/userProfile/${userId}`);
  };

  const getTimeAgo = (timestamp) => {
    const now = new Date();
    const storyDate = new Date(timestamp);
    const diffInSeconds = Math.floor((now - storyDate) / 1000);

    if (diffInSeconds < 60) {
      return `${diffInSeconds} секунд назад`;
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes} минут назад`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} часов назад`;
    } else {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} дней назад`;
    }
  };

  const pauseStory = () => {
    setIsPaused(true);
    clearInterval(intervalRef.current); // Останавливаем прогресс
  };

  const resumeStory = () => {
    setIsPaused(false); // Снова запускаем прогресс
  };

  useEffect(() => {
    setCurrentStoryIndex(0);
  }, [stories]);

  if (!currentStory) {
    return null;
  }

  return (
    <div className="story-viewer">
      <div className="story-header">
        <div className="user-info" onClick={handleProfileRedirect}>
          <img src={logoImage} alt={login} className="avatar" />
          <div className="user-details">
            <span className="login">{login}</span>
            <span className="time-ago">{getTimeAgo(currentStory.createdAt)}</span>
          </div>
        </div>
        <button className="close-btn" onClick={onClose}>✕</button>
      </div>

      <div className="progress-bar">
        {stories.map((_, index) => (
          <div
            key={index}
            className={`progress-segment ${index === currentStoryIndex ? 'active' : ''}`}
            style={{
              width: `${100 / stories.length}%`,
              background: progress[index] >= 100 ? 'white' : 'rgba(255, 255, 255, 0.3)',
            }}
          >
            <div
              className="progress-fill"
              style={{
                width: `${Math.min(progress[index], 100)}%`,
                background: 'white',
              }}
            ></div>
          </div>
        ))}
      </div>

      <div
        className="story-content"
        onClick={handleContentClick}
        onMouseDown={pauseStory}
        onMouseUp={resumeStory}
        onTouchStart={pauseStory}
        onTouchEnd={resumeStory}
      >
        {currentStory.fileType.startsWith('image') ? (
          <img
            src={currentStory.fileUrl}
            alt="Story"
            className="story-media"
          />
        ) : currentStory.fileType.startsWith('video') ? (
          <video
            src={currentStory.fileUrl}
            className="story-media"
            autoPlay
            onEnded={handleVideoEnded}
            playsInline
            weblit-playsInline='true'
          ></video>
        ) : (
          <div>Unsupported file type</div>
        )}
      </div>

      <div className="story-text">
        <p>{currentStory.text}</p>
      </div>
    </div>
  );
};

export default StoryViewer;
